<template>
  <div>
    <input type="file" ref="file" style="display: none" />
    <div v-show="show_webcam">
      <div class="d-flex justify-start align-center">
        <v-btn small @click="cancel()" style="z-index: 98">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-file-input
          accept="image/*"
          label="File input"
          @change="loadImage"
          style="z-index: 98"
        ></v-file-input>
      </div>
      <div class="camera-modal">
        <video id="webcam" autoplay playsinline class="camera-stream"></video>
        <canvas id="canvas" class="d-none"></canvas>
      </div>
      <div class="d-flex justify-center">
        <v-btn
          color="red"
          dark
          fab
          absolute
          bottom
          @click="takePicture()"
          style="z-index: 9999"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="isViewingPicture" class="preview-mode">
      <div class="d-flex align-center flex-column mb-6">
        <img id="scanned-img" :src="previewURL" />
        <div class="d-flex justify-space-around">
          <div class="ma-4">
            <v-btn color="white" @click="cancelPreview">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <div class="ma-4">
            <v-btn
              color="green white--text"
              @click="acceptPhoto()"
              :disabled="workerInProgress"
            >
              <div v-if="!workerInProgress">
                <v-icon>mdi-arrow-right</v-icon>
                Next
              </div>
              <div v-else>
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
            </v-btn>
          </div>
        </div>
      </div>

      <div>
        <img id="gray-img" src="" />
      </div>
    </div>
    <v-snackbar v-model="snackbar" color="red">
      {{ snackbarText }}}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon @click="snackbar = false" v-bind="attrs">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Webcam from "webcam-easy";
import { fromBlob, fromURL, blobToURL } from "image-resize-compress";
import { mapGetters } from "vuex";
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  doc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
export default {
  computed: {
    ...mapGetters(["general_info", "lotteries_info", "getUser"]),
  },
  data() {
    return {
      show_webcam: true,
      webcamElement: null,
      canvasElement: null,
      webcam: null,
      isViewingPicture: false,
      previewURL: null,
      blob: null,
      numbers: "",
      uploadToAIStatus: true,
      worker: null,
      workerInProgress: false,
      workerProgressValue: 0,
      workerProgressPourcentage: 0,
      snackbar: false,
      snackbarText: "",
      user_ticket: null,
      valid_ticket: true,
      lottery_days: {
        megamillions: [2, 5],
        powerball: [1, 3, 6],
      },
      draw_date_mapping: {
        // Monday, Wednesday, Saturday
        powerball: {
          0: 1, // Sunday
          1: 0, // Monday
          2: 1, // Tuesday
          3: 0, // Wednesday
          4: 2, // Thursday
          5: 1, // Friday
          6: 0, // Saturday
        },
        // Tuesday, Friday
        megamillions: {
          0: 2, // Sunday
          1: 1, // Monday
          2: 0, // Tuesday
          3: 2, // Wednesday
          4: 1, // Thursday
          5: 0, // Friday
          6: 3, // Saturday
        },
      },
    };
  },

  mounted() {
    this.fetch_assigned_orders();
    this.$store.dispatch("fetch_general_info");
    this.init_cam();
  },
  destroyed() {
    // this.stopWebcam();
    if (this.webcam != null) {
      this.webcam.stop();
    }
  },
  methods: {
    init_cam() {
      console.log("init_cam");
      this.isViewingPicture = false;
      this.show_webcam = true;
      this.webcamElement = document.getElementById("webcam");
      this.canvasElement = document.getElementById("canvas");
      this.webcam = new Webcam(
        this.webcamElement,
        "environment",
        this.canvasElement,
        null
      );
      this.webcam
        .start()
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    restart_cam() {
      this.show_webcam = true;
      console.log("restart_cam");
      // this.webcam.stream();
      this.init_cam();
    },
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var _ia = new Uint8Array(arrayBuffer);
      for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
      }

      var dataView = new DataView(arrayBuffer);
      var blob = new Blob([dataView], { type: mimeString });
      return blob;
    },
    takePicture() {
      if (this.webcam != null) {
        const blob = this.dataURItoBlob(this.webcam.snap());
        this.show_webcam = false;
        this.webcam.stop();

        this.isViewingPicture = true;

        // quality value for webp and jpeg formats.
        const quality = 80;
        // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
        const width = 1000;
        // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
        const height = "auto";
        // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
        const format = "jpeg";

        // note only the blobFile argument is required
        fromBlob(blob, quality, width, height, format).then((new_blob) => {
          // will output the converted blob file
          this.blob = new_blob;
          blobToURL(new_blob).then((url) => (this.previewURL = url));
        });
      } else {
        this.isViewingPicture = true;
      }
    },
    getPoolsFromString(play) {
      var pool_result = [];
      var poolsStr = play.split(" ");

      var numbers = [];
      // Parse first part
      if (poolsStr.length >= 1) {
        var pool1Str = poolsStr[0].split("-");

        for (var numberStrIdx in pool1Str) {
          var parsedNumber = parseInt(pool1Str[numberStrIdx]);
          if (!isNaN(parsedNumber)) {
            numbers.push(parsedNumber);
          }
        }
      }
      pool_result.push({ numbers: numbers });

      // Parse second part
      numbers = [];
      if (poolsStr.length >= 2) {
        var pool2 = parseInt(poolsStr[1].replace("(", "").replace(")", ""));
        if (!isNaN(pool2)) {
          numbers.push(pool2);
        }
      }
      pool_result.push({ numbers: numbers });

      return pool_result;
    },
    cancelPreview() {
      this.isViewingPicture = false;
      this.restart_cam();
    },

    cancel() {
      this.$store.commit("stopListeningOrder");
      this.$router.back();
    },
    loadImage(file) {
      console.log("Files: ", file);
      if (file != undefined) {
        // this.stopWebcam();
        this.webcam.stop();
        var inputUrl = URL.createObjectURL(file);
        // quality value for webp and jpeg formats.
        const quality = 30;
        // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
        const width = 400;
        // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
        const height = "auto";
        // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
        const format = "jpeg";

        // note only the blobFile argument is required
        fromURL(inputUrl, quality, width, height, format).then((new_blob) => {
          // will output the converted blob file
          this.blob = new_blob;
          blobToURL(new_blob).then((url) => (this.previewURL = url));
        });
        this.blob = file;
        this.isViewingPicture = true;
      }
    },
    convertGridsToStringArray(grids) {
      var result = [];
      for (var play of grids) {
        // var play_str = play.pool[0].numbers.join("-");

        var play_str = play.pool[0].numbers
          .map((number) => {
            return number < 10 ? `0${number}` : `${number}`;
          })
          .join("-");

        play_str += ":";
        //play_str += play.pool[1].numbers.join("-");
        play_str += play.pool[1].numbers
          .map((number) => {
            return number < 10 ? `0${number}` : `${number}`;
          })
          .join("-");
        result.push(play_str);
      }
      return result;
    },
    findUserOrder(ticket) {
      var result = {
        top: {},
        normal: {},
      };

      for (var order of this.order_list) {
        for (var product_index in order.products) {
          var product = order.products[parseInt(product_index)];

          if (product.ready != undefined && product.ready == true) {
            continue;
          }
          // Multi play check
          if (
            (product.ticket.multiplay && ticket.draw_dates.length == 1) ||
            (!product.ticket.multiplay && ticket.draw_dates.length > 1)
          ) {
            continue;
          }

          // Multiplier check
          if (product.ticket.multiplier != ticket.multiplier) {
            continue;
          }

          if (product.ticket.type_name != ticket.type) {
            continue;
          }

          // Get the number of plays
          var grids = 0;
          if (product.ticket.grids != undefined) {
            grids = product.ticket.grids.length;
          } else {
            grids = product.ticket.quickPicks;
          }

          if (grids != ticket.grids.length) {
            continue;
          }

          // Check if the ticket is for a grid order
          if (product.ticket.grids != undefined) {
            var order_grids = this.convertGridsToStringArray(
              product.ticket.grids
            );
            var play_count = 0;
            for (var ticket_grid of ticket.grids) {
              if (order_grids.includes(ticket_grid)) {
                play_count++;
              }
            }

            if (play_count == ticket.grids.length) {
              // If the ticket fit with a grid order,
              // Add the user in the top list
              result.top[order.id] = {
                order: order,
                product_index: product_index,
                client_id: order.client_id,
              };
              if (result.normal[order.id] != undefined) {
                delete result.normal[order.id];
              }
            } else if (result.top[order.id] == undefined) {
              // If the user is not already in the top list, add it in normal
              result.normal[order.id] = {
                order: order,
                product_index: product_index,
                client_id: order.client_id,
              };
            }
          } else {
            // If the ticket is for a quick pick,
            // Add the user in the normal list
            result.normal[order.id] = {
              order: order,
              product_index: product_index,
              client_id: order.client_id,
            };
          }
        }
      }
      return result;
    },
    acceptPhoto() {
      if (this.order_list == undefined) {
        console.error("No order list, reload the page");
        return;
      }
      this.workerInProgress = true;
      // Upload photo to temporary space
      var id = doc(collection(getFirestore(), "collection")).id;
      var metadata = {
        customMetadata: {
          user_id: this.getUser.uid,
          id: id,
        },
      };

      const storageRef = ref(getStorage(), "/temp_batch_scan/" + id + ".jpg");
      uploadBytes(storageRef, this.blob, metadata)
        .then((snapshot) => {
          console.log("snapshot: ", snapshot);
          console.log("Uploaded", snapshot.metadata.size, "bytes.");
          var path = snapshot.ref.fullPath;
          getDownloadURL(snapshot.ref).then((url) => {
            console.log("Download url: ", url);

            const scanTicketFunc = httpsCallable(getFunctions(), "scanTicket");
            scanTicketFunc({
              photo_url: "/temp_batch_scan/" + id + ".jpg",
              // photo_url: "/temp_batch_scan/pb_3plays_wpp.jpg",
            }).then((response) => {
              this.workerInProgress = false;
              console.log("Response: ", response);
              console.log("Ticket: ", response.data.ticket);
              console.log("Order list: ", this.order_list);
              if (response.data.ticket == undefined) {
                this.snackbarText =
                  "Ticket scan error, press back and scan again.";
                this.snackbar = true;
                return;
              }
              var user_orders_list = this.findUserOrder(response.data.ticket);
              console.log("user order list: ", user_orders_list);
              if (
                Object.keys(user_orders_list.top).length == 0 &&
                Object.keys(user_orders_list.normal).length == 0
              ) {
                this.snackbarText = "No order found for this ticket";
                this.snackbar = true;
                this.init_cam();
                return;
              }
              this.$router.push({
                name: "ScanBatchSelectCustomer",
                params: {
                  ticket: response.data.ticket,
                  image_info: { url: url, path: path },
                  user_orders_list: user_orders_list,
                },
              });
            });
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.workerInProgress = false;
        });
    },
    fetch_assigned_orders() {
      // Create a query for documents in the 'orders' collection with status 'ASSIGNED' and order them by 'order_date' ascending
      const q = query(
        collection(getFirestore(), "orders"),
        where("status", "==", "ASSIGNED"),
        orderBy("order_date", "asc")
      );

      // Execute the query and retrieve the resulting documents
      getDocs(q)
        .then((querySnapshot) => {
          var assignedDocs = [];
          for (var doc of querySnapshot.docs) {
            assignedDocs.push(doc.data());
          }
          this.order_list = assignedDocs;
          console.log("order list: ", this.order_list);
        })
        .catch((error) => {
          console.log(`Error getting documents:", ${error}`);
        });
    },
  },
};
</script>

<style scoped>
.camera-modal {
  margin: 0;
  padding: 0;
}
.camera-stream {
  width: 100%;
  max-height: 100%;
}
.take-picture-button {
  display: flex;
}
.preview-mode {
  width: 100%;
  position: relative;
  z-index: 10;
}
</style>
